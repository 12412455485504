
.mainContainer {
  padding: 5px;
}


.caption {

}

.footer {

}


.allTablesContainer {
 padding: 10px;
}

.tableContainer {
  margin: 15px;
}


.tableStyle {
    border: 1px solid black;
}

.redText {
  color: red;
}

.tempTable7 {

    width: 600px;
    height: 375px;
    background-color: #e9e9e9;
}

.tempTable15 {

    width: 1100px;
    height: 650px;
    background-color: #e9e9e9;
}


